// post archive
.blog-archive {
  padding-top: 109px;
  @media (max-width: 991px) {
    padding-top: 0;
  }

    h1 {
      text-align: center;
      font-size: 70px;
      text-transform: uppercase;
      font-weight: bold;
      line-height: normal;
      margin: 30px 0;
    }

    .paginator {
      margin: 20px 0 40px;
    }

    .pagination > li > a {
      border: none;
      &:hover {
        background: #151515;
      }
    }

    .pagination > .active > a {
      background-color: #151515;
      border-radius: 2px;
    }
}

.blog-post-image {
  height: 500px;
  overflow: hidden;
  @media (max-width: 640px) {
    height: 200px;
  }

    div {
      height: inherit;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      transition: all .3s ease-out;
      &:hover {
        transform: scale(1.1) rotate(2deg);
      }
    }

    a {
      display: block;
      height: inherit;
      background-color: #151515;
      opacity: 0;
      transition: all .3s ease-out;
      &:hover {
        opacity: 0.4;
      }
    }
}



.blog-post-text {
  border-left: 2px solid;
  padding-left: 20px;
  @media (max-width: 480px) {
    margin-bottom: 40px;
  }

    h2 {
      font-weight: bold;
      font-size: 32px;
      margin: 0;
      padding: 15px 0 12px;
      @media (max-width: 480px) {
        font-size: 22px;
        line-height: normal;
      }

        a:hover {
          text-decoration: none;
          opacity: .7;
        }
    }

    hr {
      border-top-color: #151515;
      margin: 18px 0;
      @media (max-width: 480px) {
        display: none;
      }
    }

    p {
      margin-bottom: 50px;
      @media (max-width: 480px) {
        display: none;
      }
    }
}



.blog-post-data {
  display: flex;
  align-items: center;

    span {
      color: #6e7275;
      padding-left: 7px;
    }
}



.blog-categories {
  padding-left: 20px;
  border-left: 2px solid #151515;
  border-top: 2px solid #151515;
  margin-top: 18px;
  @media (max-width: 991px) {
    margin: 50px 0;
  }

    &.about-us {
      margin-bottom: 60px;

        img {
          padding: 18px 0 10px;
          width: 100%;
          max-width: 180px;
        }

        p {
          line-height: 1.5;
          padding-bottom: 20px;
          margin-bottom: 0;
        }

        a {
          background-color: #151515;
          color: white;
          display: block;
          text-align: center;
          padding: 10px 0;
          text-transform: uppercase;
          letter-spacing: 0.05em;
        }
    }

    h3 {
      background-color: #151515;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      margin: -20px 0 0;
      padding: 6px 15px;
      font-size: 18px;
      text-align: center;
    }

    ul {
      list-style: none;
      padding: 20px 0;
      margin: 0;
    }

    i {
      padding-right: 7px;
    }

    a {
      font-size: 16px;
      letter-spacing: 0.03em;
      &:hover {
        opacity: .6;
        text-decoration: none;
      }
    }

    li {
      padding: 3px 0;
    }
}

.blog-sidebar {
  width: 262px;
  top: 130px;
  @media (max-width: 1200px) {
    width: 212px;
  }
  @media (max-width: 991px) {
    display: none;
  }
}



// single post
.blog-post-cover {
  margin-top: 124px;
  @media (max-width: 991px) {
    margin-top: 30px;
  }
  @media (max-width: 480px) {
    margin-top: 16px;
  }

    img {
      width: 100%;
    }
}

.blog-post-title {
  font-weight: bold;
  font-size: 38px;
  line-height: normal;
  margin: 30px 0 20px;
  @media (max-width: 480px) {
    font-size: 22px;
    margin: 20px 0 20px;
  }
}

.blog-post-date {
  display: inline-block;
  margin-right: 20px;
  color: #4f5254;

    img {
      position: relative;
      margin: -2px 3px 0 0;
    }
}

.blog-post-categories {
  display: inline-block;

    img {
      position: relative;
      margin: -2px 6px 0 0;
    }

  a {
    padding-right: 3px;
    color: #4f5254;

      &:not(:last-child)::after {
        content: ',';
      }
      &:hover {
        text-decoration: none;
        opacity: .7;
      }
  }

}


.blog-post-content {
  margin-top: 25px;

    p {
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 12px;
      letter-spacing: 0.01em;
      @media (max-width: 480px) {
        font-size: 15px;
      }
    }

    h2 {
      margin: 27px 0 7px;
    }

    b, strong {
      color: #151515;
    }

    img {
      width: 100%;
      margin-bottom: 30px;
    }
}


.blog-post-wrapper {

    .blog-post-image {
      height: 330px;
      @media (max-width: 480px) {
        height: 200px;
      }
    }

    .blog-post-text h2 {
      font-size: 22px;
      line-height: normal;
      padding: 15px 0 0;
      margin-bottom: 40px;
    }

    .striped {
      background-color: #151515;
      color: white;
      text-transform: uppercase;
      letter-spacing: 0.03em;
      padding: 9px 15px;
    }
}

.social-share {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ced0d8;
  padding: 15px 0 45px;
  @media (max-width: 480px) {
    flex-direction: column;
  }
}

.social-button {
  color: white;
  padding: 7px 20px;
  border-radius: 2px;

    &.facebook {
      background-color: #1b54bf;
    }

    &.twitter {
      background-color: #1ba3e6;
    }

    &:hover {
      text-decoration: none;
      opacity: .7;
      color: white;
    }

    i {
      padding-right: 5px;
    }
}