/*Style Switcher
------------------------------------*/
/*General*/
i.style-switcher-btn {
  right: 0;
  top: 40px;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  z-index: 555555;
  position: fixed;
  padding: 9px 10px;
  background: #222;
  border-radius: 3px 0 0 3px !important;
}

i.style-switcher-btn:hover {
  background: #000;
}

i.style-switcher-btn-option {
  top: 38px;
  background: #9097a0;
}

i.style-switcher-btn-option:hover {
  background: #707985;
}

.style-switcher {
  right: 0;
  top: 40px;
  color: #fff;
  width: 235px;
  display: none;
  z-index: 555555;
  position: fixed;
  background: #333;
  border-radius: 3px 0 0 3px !important;
}

/*Others*/
.style-switcher .style-switcher-heading {
  color: #fff;
  font-size: 15px;
  font-weight: 200;
  margin-bottom: 10px;
}

.style-switcher-logo {
  margin-top: 10px;
  padding-top: 15px;
  border-top: solid 1px #4c5159;
}

.style-switcher-logo a {
  display: block;
  text-align: center;
}

.style-switcher .btn-u {
  font-weight: 200;
  border: solid 1px #555;
  border-radius: 2px !important;
}

.style-switcher .btn-u.active-switcher-btn,
.style-switcher .btn-u.active-switcher-btn {
  border: solid 1px #bbb;
}

/*Style Swticher Header*/
.style-swticher-header {
  background: #252525;
  padding: 9px 10px 5px 15px;
  border-bottom: solid 1px #222;
  border-radius: 3px 0 0 0 !important;
}

.style-swticher-header .style-switcher-heading {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
}

.style-swticher-header .theme-close {
  top: 9px;
  right: 6px;
  position: absolute;
}

.style-swticher-header .theme-close i {
  color: #999;
  padding: 5px;
  cursor: pointer;
  font-size: 22px;
}

.style-swticher-header .theme-close i:hover {
  color: #fff;
}

/*Style Swticher Body*/
.style-swticher-body .no-col-space {
  padding: 0 10px;
  text-align: center;
}

.style-swticher-body hr {
  margin: 11px 0;
  border-color: #454545;
}

.style-swticher-body .no-col-space .col-xs-6 {
  padding-left: 5px;
  padding-right: 5px;
}

.style-swticher-body {
  padding: 15px 10px 15px 15px;
}

/*Theme Colors*/
.style-switcher li {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background: #c00;
  margin: 0 6px 6px 0;
  display: inline-block;
  border-radius: 10% !important;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.style-switcher li.last {
  margin-right: 0;
}

.style-switcher li:hover,
.style-switcher li.theme-active {
  border: solid 2px #fff;
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.style-switcher li.theme-default {
  background: #72c02c;
}

.style-switcher li.theme-blue {
  background: #3498db;
}

.style-switcher li.theme-orange {
  background: #e67e22;
}

.style-switcher li.theme-red {
  background: #e74c3c;
}

.style-switcher li.theme-light {
  background: #ecf0f1;
}

.style-switcher li.theme-purple {
  background: #9b6bcc;
}

.style-switcher li.theme-aqua {
  background: #27d7e7;
}

.style-switcher li.theme-brown {
  background: #9c8061;
}

.style-switcher li.theme-dark-blue {
  background: #4765a0;
}

.style-switcher li.theme-light-green {
  background: #79d5b3;
}

.style-switcher li.theme-dark-red {
  background: #a10f2b;
}

.style-switcher li.theme-teal {
  background: #18ba9b;
}

.style-switcher li.theme-green {
  background: #72c02c;
}