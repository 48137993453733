@import "app";
@import "blocks";
@import "shop.style";
@import "plugins/style-switcher";
@import "components/footer-v4";
@import "components/header-v5";
@import "components/log-reg-v3";
@import "components/select";
@import "components/blog";
@import "theme/gold";

/* Add here all your css styles (customizations) */

$primary-color: $gold;

@mixin box-shadow($shadow...) {
    -webkit-box-shadow: $shadow; // iOS <4.3 & Android <4.1
    box-shadow: $shadow;
}

* {
    font-family: "Asap", Helvetica, sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Asap", Helvetica, sans-serif;
    color: #151515;
}

p {
    font-family: "Asap", Georgia, serif;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wizard > .steps a {
    display: block;
    color: #333;
    position: relative;
    text-decoration: none;
    margin-bottom: 10px;
}

.wizard > .steps > .step.done a,
.wizard > .steps > .step.current a,
.wizard > .steps > .step.done a h2,
.wizard > .steps > .step.done a p,
.wizard > .steps > .step.current a h2,
.wizard > .steps > .step.current a p {
    color: white;
}

.wizard > .steps a h2,
.wizard > .steps a p {
    color: #333;
    padding-left: 40px;
    margin-top: 10px;
}

.wizard > .steps > .step.done a .rounded-x,
.wizard > .steps > .step.current a .rounded-x {
    border-color: white;
}

.wizard > .steps a .rounded-x {
    border: 2px solid #333;
    padding: 8px;
    position: absolute;
    right: 10px;
    top: 10px;
}

.wizard > .steps a .number {
    position: absolute;
    top: 10px;
    font-size: 24px;
    left: 10px;
}

.fa-spacing {
    margin-right: 5px;
}

.fa-spacing-before {
    margin-left: 5px;
}

.header-v5 .navbar-brand > img {
    height: 50px;
}

.header-v5.header-fixed-shrink .navbar-brand > img {
    height: 40px;
}

.footer-v4 img.footer-logo {
    width: 100%;
}

.log-reg-v3 .log-reg-block label {
    width: 100%;
}

.log-reg-v3 .log-reg-block label.error {
    color: #c9253c;
    padding-left: 0;
}

.log-reg-v3 .reg-input .form-control.error {
    border-color: #c9253c;
}

.illustration-v2 .product-img {
    margin-top: 10px;
    height: 0px;
    overflow: hidden;
    padding-bottom: 100%;
    width: 100%;
}

@media (max-width: 998px) {
    .header-v5 .navbar-brand {
        margin-top: -15px;
        width: 80%;
    }
    .header-v5 .navbar-brand > img {
        max-width: 100%;
    }
}

.wishlist-category .tag {
    display: inline-block;
    border: 1px solid;
    color: #7f8488;
    padding: 5px 15px;
    text-transform: uppercase;
    margin-right: 8px;
    text-decoration: none;
    @media (max-width: 640px) {
        padding: 3px 8px;
        margin-bottom: 8px;
    }
    &:hover {
        color: $black;
    }
}

.wishlist-category {
    padding-top: 1px;
}

.shop-product .product-color .color-link {
    width: 50px;
    height: 50px;
    float: right;
    padding: 5px;
    display: block;
    font-size: 24px;
    text-align: center;
}

.horizontal-spaced {
    padding-left: 5px;
    padding-right: 5px;
}

.ml-20 {
    margin-left: 20px;
}

.ml-30 {
    margin-left: 30px;
}

.toggle-subfilter {
    color: #999;
    float: right;
    margin: 4px 0;
    cursor: pointer;
}

.toggle-subfilter span {
    line-height: 25px;
}

.checkbox-list li > ul {
    display: none;
}

.checkbox-list li > .toggle-subfilter .fa-minus {
    display: none;
}

.checkbox-list li > .toggle-subfilter .fa-plus {
    display: block;
}

.checkbox-list li.open > ul {
    display: block;
}

.checkbox-list li.open > .toggle-subfilter .fa-minus {
    display: block;
}

.checkbox-list li.open > .toggle-subfilter .fa-plus {
    display: none;
}

/*Grid List Icons*/

.grid-list-icons a {
    margin-left: 5px;
}

.grid-list-icons {
    float: right;
    margin-top: 9px;
}

.grid-list-icons .grid-list-icon {
    display: inline-block;
}

.grid-list-icons .grid-list-icon label {
    color: #fff;
    width: 30px;
    height: 30px;
    padding: 6px;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    background: #cfd1d1 none repeat scroll 0% 0%;
}

.grid-list-icons .grid-list-icon input[type="radio"] {
    display: none;
}

.grid-list-icons .grid-list-icon:hover label {
    background: #cfd1d1 none repeat scroll 0% 0%;
    text-decoration: none;
}

.grid-list-icons .grid-list-icon input[type="radio"]:checked + label {
    background: $primary-color none repeat scroll 0% 0%;
}

#catalog-wrapper {
    position: relative;
    padding-top: 12px;
}

.catalog-loading-overlay {
    background-color: rgba(255, 255, 255, 0.4);
    bottom: 0;
    display: none;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
}

.catalog-loading .catalog-loading-overlay {
    display: block;
}

.list-product-description {
    border: 1px solid #151515;
}

.list-product-description .shop-product {
    padding: 10px 0px;
    border-bottom-width: 0px;
}

.shop-product .product-size.small label {
    width: 30px;
    height: 30px;
    padding: 1px 0px;
    font-size: 15px;
}

.product-in-table .product-id h3 {
    color: #687074;
}

.product-in-table .product-id {
    color: #888;
}

.product-in-table .product-id:hover {
    text-decoration: none;
}

.radio,
.inline-radio {
    padding-left: 15px;
}

.radio label,
.inline-radio label {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    padding-left: 5px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radio input[type="radio"],
.inline-radio input[type="radio"] {
    display: none;
}

.radio input[type="radio"] + label:before,
.inline-radio input[type="radio"] + label:before {
    border: 1px solid #ccc;
    border-radius: 10px;
    content: "";
    cursor: pointer;
    height: 16px;
    left: -15px;
    position: absolute;
    top: 2px;
    width: 16px;
    background-image: url(../img/radio-checked.svg);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 0px 0px;
    transition: all 0.15s ease-in-out 0s;
}

.radio input[type="radio"]:checked + label:before,
.inline-radio input[type="radio"]:checked + label:before {
    background-size: 14px 14px;
}

.radio label,
.inline-radio label,
.checkbox label,
.inline-checkbox label {
    line-height: 1.7;
}

.inline-checkbox {
    display: inline-block;
}

.inline-checkbox label {
    min-width: 60px;
    padding-right: 10px;
}

.inline-radio {
    display: inline-block;
}

.inline-radio label {
    min-width: 60px;
    padding-right: 10px;
}

.form-horizontal .checkbox {
    padding-top: 0;
}

.margin-right-5 {
    margin-right: 5px;
}

.wizard ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.shopping-cart table .quantity-button {
    vertical-align: bottom;
    padding: 10px 5px;
}

.order-inner-link > a {
    display: block;
}

.order-row {
    cursor: pointer;
}

.order-controls:hover {
    background-color: transparent !important;
}

.order-controls .order-controls-row {
    margin-bottom: 8px;
}

.order-controls .order-controls-row:last-child {
    margin-bottom: 0;
}

.order-controls .order-controls-row .label {
    line-height: 33px;
    text-transform: capitalize;
}

.order-controls .order-controls-return > thead > tr > th {
    text-transform: capitalize;
}

.order-controls .order-controls-return > thead > tr > td {
    vertical-align: middle;
}

.order-controls {
    display: none;
}

.table > tbody > tr.order-controls {
    border-left: 3px solid $primary-color;
}

.table > tbody > tr.order-controls > td {
    padding-left: 18px;
}

.new-return-request {
    display: none;
}

.row-returned-goods dl {
    margin-bottom: 0;
}

.row-returned-goods .cancel-request {
    margin-top: 15px;
}

@media screen and (min-width: 800px) {
    .row-returned-goods .cancel-request {
        margin-top: 42px;
    }
}

.header-v5 .navbar > .container .navbar-brand {
    padding: 0;
    margin: 12px 10px 10px 0;
    height: 50px;
    > img {
        height: 100%;
    }
    @media screen and (max-width: 991px) {
        margin: 0 0 0 5px;
        width: 70%;
    }
}

.header-fixed .header-v5.header-fixed-shrink .navbar-brand {
    top: 10px;
    margin: 0px;
    @media screen and (max-width: 991px) {
        top: 0;
    }
}

.header-fixed .header-v5.header-fixed-shrink .navbar-brand img {
    width: auto;
}

.footer-v4 img.footer-logo {
    margin: 0px 0 30px;
}

// CATALOG

.filter-by-block {
    a:hover,
    a:focus {
        text-decoration: none;
    }
}

.list-layout {
    .list-product-description .shop-product {
        padding: 10px;
    }
}

// HOME SLIDER

.home-subheader {
    display: table;
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin: 18px 0;

    .home-subheader-row {
        display: table-row;
    }

    .home-subheader-cell {
        display: table-cell;
        width: 33.3%;
        border-left: 1px solid #ced0d8;
        border-right: 1px solid #ced0d8;
        @media screen and (max-width: 768px) {
            display: block;
            width: 100%;
            border-left: 0;
            border-right: 0;
            border-bottom: 1px solid #ced0d8;
            border-top: 1px solid #ced0d8;
            padding: 10px 0;
            margin-top: -1px;
        }
    }

    h3 {
        text-transform: uppercase;
        margin: 0;
        @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: normal;
        }
    }

    p {
        margin: 0;
        font-size: 16px;
    }
}

$home-banner-height: 415px;

#owl-home-slider {
    $slider-height: $home-banner-height;
    height: $slider-height;
    @media screen and (max-width: 992px) {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 480px) {
        height: 300px;
    }

    .slide-image {
        height: $slider-height;
        background-position: center center !important;
        @media screen and (max-width: 480px) {
            height: 300px;
        }
    }
}

.home-call-to-action {
    border: 2px solid #151515;
    border-radius: 3px;
    height: $home-banner-height;
    text-align: center;
    padding: 30px;

    h3 {
        text-transform: uppercase;
        margin: 0 0 10px;
        &.logged {
            margin-top: 75px;
            line-height: normal;
        }
    }

    p {
        font-size: 18px;
        max-width: 250px;
        margin: 0 auto;
    }

    a {
        display: block;
        background-color: #151515;
        color: white;
        padding: 14px 0;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-top: 20px;
        cursor: pointer;
        transition: all 0.15s ease-out;
        &:hover {
            text-decoration: none;
            opacity: 0.7;
        }
    }

    hr {
        margin: 40px 0 50px;
        @media screen and (max-width: 1200px) {
            margin: 30px 0 38px;
        }
        @media screen and (max-width: 991px) {
            margin: 40px 0 50px;
        }
    }
}

.home-small-image {
    float: left;
    width: 50%;
    height: $home-banner-height / 2;
    background-position: center center;
    background-size: auto 100%;
}

.owl-wrapper .horizontal-spaced {
    padding-top: 10px;
}

// PRODUCT PAGE

.shop-gold {
    color: $black;
}

.color-container {
    display: flex;
    align-items: center;

    .color {
        background-color: var(--bg-color);
        width: 24px;
        height: 24px;
        border: 1px solid $grey;
        border-radius: 50%;
    }

    span {
        margin-left: 8px;
        text-transform: capitalize;
    }
}

.table.sizes {
    thead > tr > th,
    tbody > tr > td {
        &:nth-child(2), &:nth-child(3) {
            text-align: center;
        }
    }
}

.table.sizes thead > tr > th {
    text-transform: capitalize;
    font-weight: normal;
    border-bottom: 1px solid $black;
}

.table.sizes > tbody > tr > td {
    line-height: 35px;
    font-size: 18px;
    border-bottom: 1px solid $black;
}

.shop-product .quantity-field {
    display: inline-block;
    vertical-align: top;
    border: none;
    margin: 0;

    padding: 0;
}

.shop-product .quantity-button {
    background-color: $black;
    color: white;
    &:hover {
        background-color: $dark-grey;
    }
}

@media screen and (max-width: 386px) {
    .small-xs {
        width: 40px;
        max-width: 60px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: clip;
        text-overflow: clip ".";
    }
}

.btn-u {
    text-transform: uppercase;
}

.shop-product .shop-product-heading {
    font-weight: bold;
    border-bottom-color: #ced0d8;
}

.product-description .title-product {
    font-weight: bold;
    font-size: 18px;
    a,
    a:hover {
        color: $black;
    }
}

// MEGAMENU

.header-v5 h3.mega-menu-heading a {
    color: #7f8488;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.heading.heading-v1 {
    overflow-x: hidden;
}

label.error {
    color: red !important;
}

input.error {
    border-color: red !important;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.specifies-list {
    font-size: 16px;
    color: #7f8488;
    margin-bottom: 10px;
}

@media screen and (max-width: 480px) {
    .header-v5 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu li a {
        font-size: 20px;
    }
    .header-v5 .mega-menu .mega-menu-content {
        text-align: center;
    }
    .header-v5 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu li {
        padding-bottom: 10px;
    }
}

.header-v5 .dropdown-menu .style-list li > a:hover {
    cursor: pointer;
}

.topbar-v3 {
    background: $black;
}

.topbar-v3 li a {
    font-weight: 500;
}

.header-v5 .navbar-default .navbar-nav > li > a {
    color: $black;
    letter-spacing: 0.05em;
}

.header-v5 .navbar-default .navbar-nav > li:hover > a {
    color: $black;
}

.header-v5 .shop-badge.badge-icons i {
    color: #a9abad !important;
}

.heading {
    h2 {
        color: $black;
        font-size: 33px;
        font-weight: 900;
    }
    h2:after,
    h2:before {
        display: none;
    }
}

.product-description .title-price {
    color: $black;
    font-size: 18px;
    font-weight: 700;
}

.product-description h4.title-price a {
    font-weight: 400;
    font-size: 14px;
    text-transform: uppercase;
}

.illustration-v2 .product-img-brd {
    box-shadow: none;
    border: 1px solid black;
    z-index: 1;
}

span.title-price.line-through {
    color: #848484;
}

.product-img.product-img-brd {
    border: 1px solid #ced0d8;
    border-radius: 3px;
    overflow: hidden;
}

.heading {
    p {
        line-height: 24px;
        padding: 12px 200px;
        @media screen and (max-width: 992px) {
            padding: 12px 30px;
        }
    }
}

.result-category {
    h2 {
        font-weight: 700;
        color: #212121;
    }
}

.breadcrumb-v5 li a {
    color: #7b8488;
    font-size: 14px;
}

.breadcrumb-v5 > li + li:before {
    color: #7b8488;
}

.filter-by-block h1 {
    background: none;
    font-size: 18px;
    color: $black;
    text-align: left;
    padding: 7px 0 2px;
    border-bottom: 1px solid $black;
    font-weight: bold;
    margin-bottom: 0;
}

.filter-by-block .panel-default {
    border: none;
    box-shadow: none;
}

.filter-by-block .panel-group h2 a {
    border-bottom: 1px solid #ced0d8;
    letter-spacing: 1px;
    font-weight: 300;
    color: #151515;
    font-size: 18px;
    text-transform: uppercase;
    padding: 10px 0;
}

.filter-results {
    padding: 40px 0;
    margin-bottom: 30px;
    border-top: 1px solid $black;
    border-bottom: 1px solid $black;
}

.checkbox-list .checkbox {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 1px;
    color: $black;
}

.header-v5 .dropdown-menu {
    border: 2px solid;
    border-color: $black;
    border-radius: 3px !important;
}

.header-v5 .style-list li a {
    color: $black;
}

.header-v5 .mega-menu .mega-menu-content {
    padding: 23px 10px 5px;
}

.header-v5 .mega-menu .dropdown.mega-menu-fullwidth .dropdown-menu {
    margin-left: 0;
    margin-right: 0;
}

.btn-u-lg,
a.btn-u-lg {
    font-size: 18px;
    padding: 14px 28px;
}

.specifies-list li i {
    font-size: 15px;
}

.specifies-list li span {
    color: #232323;
}

.shop-product h2 {
    font-size: 35px;
    font-weight: bold;
    color: black;
    @media screen and (max-width: 480px) {
        font-size: 25px;
    }
}

.product-service .product-service-in h3 {
    color: $black;
}

.product-service .product-service-in a {
    color: #d8cb1a;
}

.product-service .product-service-heading {
    background: $black;
}

.log-reg-v3 h2.welcome-title {
    font-weight: 700;
    line-height: normal;
    margin-top: 48px;
    @media screen and (max-width: 480px) {
        margin-top: 40px;
    }
}

.info-block-in {
    h3 {
        font-weight: 500;
        color: $black;
    }
}

.btn-u {
    padding: 12px 40px;
    font-weight: 500;
    font-size: 16px;
}

.log-reg-v3 {
    .login-input {
        .form-control {
            color: $black;
            font-size: 16px;
        }
    }
    .log-reg-block {
        border: 2px solid $black;
        box-shadow: 1px 3px 35px rgba(87, 94, 95, 0.21);
        margin: 50px 0 80px;
        border-radius: 4px;
        @media screen and (max-width: 768px) {
            margin: 0;
        }
        h2 {
            font-size: 25px;
            text-transform: none;
            font-weight: 700;
        }
    }
}

.log-reg-v3 {
    .site-statistics {
        span {
            font-weight: 700;
        }
    }
}

.shopping-cart .table > tbody > tr > td.shop-red {
    color: #151515;
    font-weight: 700;
    font-size: 20px;
}

.shopping-cart .total-result .total-price span {
    color: #151515;
    font-size: 25px;
}

.header-v5 .subtotal .subtotal-cost {
    font-weight: 700;
}

.header-v5 {
    .badge-open {
        border: 2px solid #d8cb1a;

        .mCustomScrollbar {
            height: auto;
            max-height: 300px;
        }
    }
}

span.fa.fa-minus {
    font-size: 16px;
}

span.fa.fa-plus {
    font-size: 16px;
}

.pull-left {
    max-width: 150px;
    @media screen and (max-width: 1200px) {
        max-width: 130px;
    }
    @media screen and (max-width: 768px) {
        max-width: 200px;
    }
}

.no-logged-price {
    font-size: 15px;
    line-height: 1.3;
    color: #d09d2e;
    text-transform: uppercase;
    padding-top: 5px;
}

.responsive-text {
    line-height: 1.8;
    padding: 60px 0px;
}

.responsive-text h1 {
    font-weight: 700;
    text-transform: uppercase;
}

.responsive-text h1:nth-child(n + 2) {
    padding-top: 35px;
}

.responsive-text h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 18px;
    padding-top: 11px;
    margin-bottom: 0;
}

.responsive-text tr:nth-child(odd) {
    background: rgb(227, 227, 227);
}

.image-hover-effect {
    background: rgb(216, 203, 26) none repeat scroll 0% 0%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.image-hover-effect:hover {
    opacity: 0.5;
}

.show-logged {
    display: none;
}

.hide-logged {
    display: inline-block;
}

.show-not-logged {
    display: block;
}

.hide-not-logged {
    display: none;
}

.show-logged {
    display: none;
}

.hide-logged-inline {
    display: inline-block;
}

.show-not-logged-inline {
    display: inline-block;
}

.hide-not-logged {
    display: none;
}

body.logged {
    .show-logged-inline {
        display: inline-block;
    }
    .show-logged-inline {
        display: inline-block;
    }
    .show-logged {
        display: block;
    }
    .hide-logged {
        display: none;
    }
    .show-not-logged {
        display: none;
    }
    .hide-not-logged {
        display: block;
    }
    .hide-not-logged-inline {
        display: inline-block;
    }
}

.col-sm-6.col-sm-offset-3.responsive-text {
    padding: 60px 0 120px;
    @media screen and (max-width: 480px) {
        padding: 60px 10px;
    }
}

.footer-v4 .simple-list a {
    font-weight: 400;
}

.content-404 {
    text-align: center;
    margin: 180px 0px;
    @media screen and (max-width: 480px) {
        margin: 50px 0px;
    }

    h1 {
        font-size: 150px;
        font-weight: 900;
        line-height: 100px;
    }
    h2 {
        font-family: "Merriweather";
        text-transform: capitalize;
        font-size: 28px;
        padding-top: 10px;
    }
    p {
        margin-bottom: 40px;
    }
    a {
        border: 2px solid;
        font-size: 16px;
        padding: 10px 30px;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-weight: 500;
    }
}

#owl-home-slider {
    .slide-image {
        background-size: cover !important;
    }

    //.owl-nav {
    //  position: absolute;
    //  top: 0;
    //  bottom: 0;
    //  left: 0;
    //  right: 0;
    //  display: flex;
    //  justify-content: space-between;
    //  align-items: center;
    //  color: white;
    //  padding: 20px;
    //}
}

.subscribe-login {
    color: black;

    a {
        background: $black none repeat scroll 0% 0%;
        padding: 10px 30px;
        margin-left: 15px;
        cursor: pointer;
        color: white;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 1px;
        @media screen and (max-width: 370px) {
            display: block;
            text-align: center;
            margin-top: 10px;
            margin-left: 0;
        }
    }
}

.margin-bottom-20 + .text-center {
    display: none;
}

.welcome-title + p {
    max-width: 560px;
}

#subscribed-container {
    text-align: center;
    padding-top: 190px;
    margin-bottom: 240px;
    @media screen and (max-width: 480px) {
        margin-top: 70px;
        margin-bottom: 150px;
    }
    h1 {
        font-size: 60px;
        font-weight: 900;
        line-height: normal;
    }
    h3 {
        font-family: "Merriweather", Georgia, serif;
        font-size: 16px;
    }
}

#profile-nav {
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
    color: #000;
}

.nav > li > a:focus,
.nav > li > a:hover {
    background-color: $black;
    color: white;
}

#profile-nav a {
    color: black;
}

.list-product-description p,
#description p {
    font-family: "Asap", Helvetica, sans-serif;
}

#description h3 {
    margin: 11px 0 4px;
    font-size: 20px;
}

.list-inline.shop-product-prices.margin-bottom-30 {
    margin: 25px 0 0;
}

.shop-product .tab-v5 + hr {
    display: none;
}

.table-models {
    padding-top: 15px;
}

.product-service {
    margin-bottom: 40px;
}

.illustration-v2.margin-bottom-60 {
    margin-bottom: 0;
}

#contact-form .btn.btn-black.save-button {
    margin-top: 20px;
    background: black;
    color: white;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

.roles {
    list-style: none;
    padding: 0;
}

ul.roles li:first-child {
    padding-left: 7px;
}

.role {
    float: left;
    border: 2px solid;
    margin: 0 7px 7px 0;
    padding: 5px 7px;
    text-transform: uppercase;
    font-size: 14px;
}

@media screen and (max-width: 480px) {
    .ms-thumb-list.ms-dir-h.ms-align-bottom {
        display: none;
    }
    ul.breadcrumb-v5 {
        display: none;
    }
}

.placeholder {
    color: rgb(136, 136, 136) !important;
}

#owl-home-slider .slide-image {
    display: table;
    width: 100%;

    > div {
        display: table-cell;
        vertical-align: bottom;
        text-align: center;
        background: rgba(21, 21, 21, 0); /* For browsers that do not support gradients */
        background: -webkit-linear-gradient(rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.44)); /* For Safari 5.1 to 6.0 */
        background: -o-linear-gradient(rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.44)); /* For Opera 11.1 to 12.0 */
        background: -moz-linear-gradient(rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.44)); /* For Firefox 3.6 to 15 */
        background: linear-gradient(rgba(21, 21, 21, 0), rgba(21, 21, 21, 0.44)); /* Standard syntax */
        transition: all 0.15s ease-out;
        &:hover {
            background: rgba(21, 21, 21, 0.75);
        }
    }

    h2 {
        color: white;
        font-weight: 700;
        margin-bottom: 30px;
        font-size: 30px;
    }

    a {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

#owl-home-slider .slide-image a:hover {
    color: #ffffff;
    text-decoration: none;
}

span.Apple-style-span {
    font-size: 20px;
    color: grey;
}

.bold-tessuti {
    color: #151515;
    font-size: 20px;
    font-weight: 900;
}

.tabella-tessuti {
    padding: 0;
    list-style: none;
}

@each $i in 5, 10, 15, 20, 30, 40, 50, 60, 80, 100, 120, 250 {
    .padding-#{$i} {
        padding: #{$i}px;
    }
    @each $dir in top, bottom, left, right {
        .margin-#{$i}-#{$dir} {
            margin-#{$dir}: #{$i}px;
        }
        .padding-#{$i}-#{$dir} {
            padding-#{$dir}: #{$i}px;
        }
    }
}

.order-controls .order-controls-row .button-label {
    line-height: 33px;
    text-transform: capitalize;
    font-weight: bold;
}

.order-controls .order-controls-return > tbody > tr > td {
    vertical-align: middle;
}

.not-found-message {
    margin: 50px;
}

.row.checkout-breadcrumb.hidden-xs {
    text-align: center;
    margin-bottom: 25px;
    padding: 0 15px;
}

.col-sm-4.checkout-breadcrumb-bit {
    background: none;
    padding: 10px 0;
    border-top: 2px solid $black;
    border-bottom: 2px solid $black;

    .breadcrumb-title {
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
    }
}

.col-sm-4.checkout-breadcrumb-bit.current {
    background: $black;
    color: white;
}

.faq-page h1 {
    margin-top: 40px;
    margin-bottom: 30px;
    font-weight: 700;
}

.faq-page h2 {
    font-size: 20px;
    font-weight: 300;
    margin-bottom: 5px;
}

.faq-page p {
    margin-bottom: 30px;
}

.faq-page {
    margin-bottom: 50px;
}

ul.list-inline.shop-social {
    display: none;
}

img.full-width.img-responsive {
    border: none;
}

.header-v5 .dropdown-menu {
    top: 67px;
    -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

.product-description .line-through {
    color: #888888;
    text-decoration: line-through;
    padding-right: 7px;
}

.row .product-description .product-price span.title-price {
    display: inline-block;
}

.row .product-description span.title-price.line-through {
    font-size: 14px;
    padding-right: 0;
    font-weight: normal;
}

.row .product-description .product-price .unit-price {
    font-size: 16px;
}

.row .product-description .unit-price-title {
    padding-top: 7px;
    letter-spacing: 0.02em;
    line-height: normal;
}

.product-description .gender {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.fixed-header-content {
    padding-top: 149px;
    @media screen and (max-width: 991px) {
        padding-top: 0;
    }
}

.home-bottom h2 {
    font-size: 38px;
    line-height: 1.1;
    position: relative;
    margin-bottom: 45px;
    @media screen and (max-width: 480px) {
        font-size: 25px;
    }
}

.home-bottom h2::after {
    content: "";
    width: 60px;
    height: 4px;
    background-color: black;
    position: absolute;
    top: auto;
    bottom: -18px;
    left: 0;
    border: none;
}

.home-bottom p {
    font-size: 16px;
    padding-right: 40px;
    line-height: 1.6;
    @media screen and (max-width: 991px) {
        padding-right: 0;
    }
}

.home-bottom-hr {
    margin: 70px 0;
    border-top: 1px solid #ced0d8;
    @media screen and (max-width: 991px) {
        margin: 30px 0;
    }
}

.heading-may-like h2 {
    font-size: 30px;
}

.product-description .gender a {
    color: $black;
    font-size: 16px;
    text-transform: uppercase;
    &:hover {
        text-decoration: none;
    }
}

.home-logos {
    margin-bottom: 30px;
}

.home-logos-separator {
    margin: 60px 0 30px;
}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 0 15px;
    @media screen and (max-width: 640px) {
        flex-direction: column;
    }

    img {
        width: 115px;
        height: 115px;
    }

    .address-list {
        margin: 0;
    }

    > div {
        @media screen and (max-width: 640px) {
            padding: 15px 0;
        }
    }
}

.header-v5 .badge-open li.empty-cart.text-center {
    padding: 28px 0 14px;
}

.ms-view {
    border: 1px solid #ced0d8;
    overflow: hidden;
    border-radius: 3px;
}

.shop-product .shop-product-heading span {
    font-weight: normal;
}

.specifies-list .line-through {
    padding-right: 5px;
}

.table.sizes > tbody > tr > .td-size {
    padding: 11px 0 0;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 8px 0;
}

.table-models .table {
    margin-bottom: 12px;
}

.add-to-cart {
    width: 100%;

    button {
        @media screen and (max-width: 991px) {
            width: 100%;
        }
    }
}

#filter-list .panel-group {
    margin-bottom: 0;
}

.shopping-cart tbody tr {
    border-bottom: 1px solid #ced0d8;
    border-top: 1px solid #ced0d8;
}

.total-result + .btn-u {
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

#checkout-container {
    padding-top: 180px;
    @media screen and (max-width: 991px) {
        margin-top: 0;
        padding-top: 30px;
    }
}

#profile-container {
    padding-top: 150px;
    @media screen and (max-width: 991px) {
        padding-top: 20px;
    }
}

.payments .btn {
    padding: 18px 12px;
    font-size: 16px;
    margin-bottom: 8px;
    border-color: $black;
}

.checkout-done {
    padding: 250px 0;
}

.margin-250-top {
    margin-top: 250px !important;
}

.cms-wrapper {
    padding-top: 140px;
    @media screen and (max-width: 991px) {
        padding-top: 30px;
    }
}

#profile-nav li {
    margin: 0;
    width: calc(100% / 3);

    @media screen and (max-width: 1200px) {
        width: auto;
    }

    &.active a {
        color: white;
    }

    &:nth-child(n + 4) {
        border-top: 2px solid $black;
    }

    a {
        text-transform: uppercase;
        letter-spacing: 0.03em;
        text-align: center;
        &:hover {
            color: white;
        }
    }
}

.product-img img {
    transition: all 0.3s ease-out;
    &:hover {
        transform: rotate(5deg) scale(1.1);
    }
}
