/*
* Version: 1.9.2
* Orange Color 		  : ;
* Orange Hover Color  : $light-yellow;
* Additional color    : $alt-yellow;
* rgba(230, 126, 34, 1);
*/

$gold: #151515;
$light-gold: lighten($gold, 10%);
$dark-gold: darken($gold, 10%);
$alt-yellow: #FEFE8D;
$black: #151515;
$dark-grey: #4B4B4B;
$grey: #B0B0B0;

a {
  color: $gold;
}

a:hover {
  color: $black;
}

/*Headers Default
------------------------------------*/

.header-v5 .navbar-default .navbar-nav > li > a:hover,
.header-v5 .navbar-default .navbar-nav > .active > a {
  border-top-color: $gold;
}

.header-v5 .navbar-default .navbar-nav > .active > a {
  color: $gold;
}

.header-v5 .navbar-default .navbar-nav > li:hover > a {
  color: $black;
}

.header-v5 .navbar-default .navbar-nav > .open > a,
.header-v5 .navbar-default .navbar-nav > .open > a:hover,
.header-v5 .navbar-default .navbar-nav > .open > a:focus {
  color: $gold;
}

.header-v5 .dropdown-menu li > a:hover {
  background: $gold;
}

.header-v5 .dropdown-menu .active > a,
.header-v5 .dropdown-menu li > a:hover {
  background: $gold;
}

.header-v5 .dropdown-menu li a:focus {
  background: #fff;
}

.header-v5 .dropdown-menu {
  border-color: $gold;
}

.header-v5 .dropdown-menu li.dropdown-submenu:hover > a {
  background: $gold;
}

.header-v5 .dropdown-menu .style-list li > a:hover {
  background: none;
}

.header-v5 .style-list li a:hover {
  color: inherit;
}

/* Shopping cart*/
/*.header-v5 .shop-badge.badge-icons i {
    color: $yellow;
}*/

.header-v5 .shop-badge span.badge-sea {
  background: $black;
}

.header-v5 .badge-open {
  border: 2px solid $black !important;
  border-radius: 3px;
}

.header-v5 li.subtotal .subtotal-cost {
  color: $gold;
}

.btn-u {
  background: $gold;
  border-color: $gold;
  color: white;
  &:hover {
    background: $light-gold;
    color: $black;
  }
  &:focus {
    background: $dark-gold;
    color: $black;
  }
}

.btn-u.btn-brd.btn-u-sea-shop {
  border-color: $black;
  color: $black;
}

.btn-u.btn-brd.btn-u-sea-shop:hover {
  border-color: $black;
  color: white;
}

.btn-u.btn-brd.btn-u-sea-shop.btn-brd-hover:hover {
  background: $gold;
}

.btn-u.btn-u-sea-shop {
  background-color: $black;
  border: 1px solid $black;
}

.btn-u.btn-u-sea-shop:hover {
  border-color: #333333;
  background: #333333;
  color: white;
}

/*Responsive Navbar*/
@media (max-width: 991px) {
  /*Responsive code for max-width: 991px*/

  .header-v5 .navbar-default .navbar-nav > .active > a,
  .header-v5 .navbar-default .navbar-nav > .active > a:hover,
  .header-v5 .navbar-default .navbar-nav > .active > a:focus {
    background: $gold !important;
  }

  .header-v5 .dropdown-menu li.dropdown-submenu:hover > a {
    background: none;
  }

}

.header-v5 .subtotal .subtotal-cost {
  color: $black;
}

/* Tab v6
------------------------------------*/
.tab-v6 .nav-tabs > li.active > a,
.tab-v6 .nav-tabs > li.active > a:hover,
.tab-v6 .nav-tabs > li.active > a:focus {
  border-color: $gold;
}

.tab-v6 .nav-tabs > li > a:hover {
  border-bottom: 1px solid $gold;
}

/* Product Rating
------------------------------------*/
.product-ratings li.reply a {
  color: $gold;
}

/* Illustration v2
------------------------------------*/
.illustration-v2 .add-to-cart:hover {
  background-color: rgba($gold, 0.5);
}

.product-description h4.title-price a:hover {
  color: $gold;
}

.product-ratings li .rating-selected {
  color: $gold;
}

.product-ratings li .rating {
  color: $alt-yellow;
}

.product-ratings li.like-icon a {
  color: $alt-yellow;
}

.product-ratings li.like-icon a:hover {
  color: $gold;
}

/* Illustration v4
------------------------------------*/
.illustration-v4 .thumb-product-purchase a {
  color: $alt-yellow;
}

.illustration-v4 .thumb-product-purchase a:hover {
  color: $gold;
}

.illustration-v4 .thumb-product-in h4 a:hover {
  color: $gold;
}

/* Twitter Block
------------------------------------*/
.twitter-block .item a {
  color: $gold;
}

/* Shop bg
------------------------------------*/
.shop-bg-green {
  background: $gold;
}

/* Shop Subscribe
------------------------------------*/
.shop-subscribe {
  background: $gold;
}

.footer-v4 .copyright a {
  color: #efefef;
}

/* Shop text
------------------------------------*/
.shop-green {
  color: $gold;
}

/* Breadcrumbs v4
------------------------------------*/
.breadcrumbs-v4 .breadcrumb-v4-in li.active,
.breadcrumbs-v4 .breadcrumb-v4-in li a:hover {
  color: $gold;
}

/* Wizard
------------------------------------*/
.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: $light-gold;
}

.wizard > .steps .disabled .number {
  color: $gold;
}

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  background: $alt-yellow;
}

.wizard > .actions a {
  background: $gold;
}

.wizard > .actions a:hover {
  background: $alt-yellow;
}

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: $gold;
}

/* Filter By Block
------------------------------------*/
.filter-by-block h1 {
  background: $gold;
}

/* Checkbox List
------------------------------------*/
.checkbox-list .checkbox input + i:after,
.checkbox-list .checkbox small a {
  color: $gold;
}

.checkbox-list .checkbox i,
.checkbox-list .checkbox:hover i,
.checkbox-list .checkbox input:focus + i,
.checkbox-list .checkbox input:checked + i {
  border-color: $gold;
}

/* noUi Connect
------------------------------------*/
.noUi-connect {
  background: $gold;
}

/* Stars Rating Label
------------------------------------*/
.stars-ratings label {
  color: $alt-yellow;
}

.stars-ratings input + label:hover,
.stars-ratings input:checked ~ label,
.stars-ratings input + label:hover ~ label {
  color: $gold;
}

/* Grid List Icon v2
------------------------------------*/
.grid-list-icons i {
  background: $gold;
}

/* Pagination v2
------------------------------------*/
.pagination.pagination-v2 > li > a:hover,
.pagination.pagination-v2 > .active > a,
.pagination.pagination-v2 > .active > span,
.pagination.pagination-v2 > .active > a:hover,
.pagination.pagination-v2 > .active > span:hover,
.pagination.pagination-v2 > .active > a:focus,
.pagination.pagination-v2 > .active > span:focus {
  background: $gold;
  border-color: $gold;
}

/* Add To Wish List
------------------------------------*/
.add-to-wishlist i {
  color: $alt-yellow;
}

.add-to-wishlist a:hover,
.add-to-wishlist .wishlist-in:hover i,
.add-to-wishlist .compare-in:hover i {
  color: $gold;
}

.add-to-wishlist a:hover,
.add-to-wishlist .wishlist-in:hover i,
.add-to-wishlist .compare-in:hover i {
  color: $gold;
}

/* Breadcrumb v5
------------------------------------*/
.breadcrumb-v5 li.active,
.breadcrumb-v5 li a:hover {
  color: $gold;
  font-size: 14px;
}

/* Shop Product Social
------------------------------------*/
.shop-product .shop-product-social li i:hover {
  color: $gold;
}

/* Shop Product
------------------------------------*/
.shop-product .product-review-list a:hover {
  color: $gold;
}

.shop-product .product-size input:checked ~ label {
  border-color: $gold;
  color: $gold;
}

.shop-product .product-size label:hover {
  border-color: $gold;
  color: $gold;
}

.shop-product .product-color input:checked ~ label {
  border: 2px solid $gold;
}

.shop-product .product-color label:hover {
  border: 2px solid $gold;
}

/* Shop Service
------------------------------------*/
.product-service .product-service-heading {
  background: $gold;
}

.product-service:hover .product-service-heading {
  background: $light-gold;
  color: $black;
}

/* Info Block v2
------------------------------------*/
.info-block-v2 i {
  color: $gold;
}

/* Log Reg v3
------------------------------------*/
.log-reg-v3 .checkbox input + i:after {
  color: $gold;
}

.log-reg-v3 .checkbox input:checked + i {
  border-color: $gold;
}

.log-reg-v3 .checkbox i {
  border-color: $light-gold;
}

.log-reg-v3 .checkbox:hover i {
  border-color: $gold;
}

.log-reg-v3 .checkbox input:focus + i {
  border-color: $dark-gold;
}

/* Cus Form Horizantal
------------------------------------*/
.cus-form-horizontal .input-small-field a {
  color: $gold;
}

.cus-form-horizontal .input-small-field a:hover {
  color: $gold;
  text-decoration: none;
}

/* Accordion v2
------------------------------------*/
.accordion-v2.plus-toggle .panel-heading a:before {
  background: $gold;
}

#topcontrol:hover {
  background-color: $gold;
}

/* Grid List Icons
------------------------------------*/
.grid-list-icons i:hover {
  background: $alt-yellow;
  text-decoration: none;
}

/* Grid List Icons
------------------------------------*/
.wishlist-category a {
  color: $gold;
}

.btn-u.btn-u-sea-shop:focus, .btn-u.btn-u-sea-shop:active,
.btn-u.btn-u-sea-shop.active, .open .dropdown-toggle.btn-u.btn-u-sea-shop {
  background: $dark-gold;
}

.nav-pills > li.active > a, .nav-pills > li.active > a:focus, .nav-pills > li.active > a:hover {
  background-color: $gold;
}
.log-reg-v3 .log-reg-block a:hover {
  color: $gold;
}