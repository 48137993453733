// Select styling

.select {
  position: relative;
  height: 48px;
  background: white;

  &.select-slim {
    height: 30px;
    width: 200px;
    margin-left: auto;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 10px;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #151515 transparent transparent transparent;
    pointer-events: none;
  }

  &:before {
    width: 30px;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background: none;
    content: '';
    pointer-events: none;
  }

  select {
    font-size: 16px;
    border: none;
    box-shadow: none;
    background: transparent;
    height: 48px;
    width: 100%;
    cursor: pointer;
    outline: none;
    padding-right: 35px;
    padding-left: 15px;
    border: 1px solid #151515;
    color: #151515;
    text-overflow: ellipsis;

    // Disable default styling on ff
    -moz-appearance: none;

    // Disable ugly ass outline on firefox
    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #151515;
    }

    // Disable default styling on webkit browsers
    -webkit-appearance: none;

    // Disable default arrow on IE 11+
    &::-ms-expand {
      display: none;
    }

    &:focus {
      border-color: gray;
    }
  }
}

// IE 9 only
@media all and (min-width:0\0) and (min-resolution:.001dpcm){
  .select{
    select {
      padding-right: 0;
    }

    &:after, &:before {
      display: none;
    }
  }
}